const TopContent = () => {
  return (
    <>
      <article className="article">
        <h2 className="article_title">GOVERNANÇA</h2>
        <p className="article_text">
          Temos uma governança que garante que as questões de desenvolvimento
          sustentável sejam tratadas por nossos acionistas, nossos
          administradores, gestores por meio de diversos comitês, círculos de
          decisão e grupos de ação.
          <br />
          <strong>
            Comitê de especialistas dedicados ao desenvolvimento sustentável
          </strong>
        </p>
      </article>
      <article className="article">
        <h2 className="article_title">
          INCORPORANDO O DESEMPENHO HUMANO, AMBIENTAL E FINANCEIRO NAS NOSSAS
          MEDIDAS DE VALOR
        </h2>
        <p className="article_text">
          Incorporamos critérios de desempenho extra financeiros em nossas
          medidas de desempenho de negócios para fornecer uma imagem completa.
          <br />
          <strong>
            Incorporação de critérios de desempenho extra financeiros no
            relatório de progresso mensal
          </strong>
        </p>
        <p className="article_text">
          Incluímos critérios de desempenho extra financeiro em nossa estrutura
          de pagamento e ao avaliar o valor das ações da empresa.
          <br />
          <strong>
            100% dos companheiros de equipe são pagos de acordo com critérios de
            desempenho extra financeiros
          </strong>
        </p>
        <p className="article_text">
          Estamos gradualmente introduzindo um preço interno de carbono em
          nossas ferramentas de tomada de decisão para ajudar a reduzir nossas
          emissões de carbono.
          <br />
          <strong>
            100% das ferramentas de tomada de decisão incorporam um preço
            interno de carbono
          </strong>
        </p>
      </article>
      <article className="article">
        <h2 className="article_title">ÉTICA DE NEGÓCIOS</h2>
        <p className="article_text">
          Ser responsável significa agir com integridade na arena dos assuntos
          econômicos e comerciais, respeitando as regras e os princípios éticos
          que subscrevemos e, assim, adotar uma política de tolerância zero em
          relação à corrupção e tráfico de influência, qualquer que seja a forma
          que assumam. O primeiro desafio é esclarecer nossos colegas de equipe
          sobre os riscos corridos local e globalmente em nível unido ao ignorar
          a ética nos negócios, bem como ajudá-los a tomar decisões
          responsáveis.
          <br />
          <strong>
            Um referencial de ética e compliance treinado e gerenciado em cada
            país
          </strong>
        </p>
        <p className="article_text">
          <strong>
            100% dos colegas de equipe têm acesso à plataforma interna de alerta
            que permite encaminhar perguntas ou alertas
          </strong>
        </p>
        <p className="article_text">
          <strong>100% dos alertas são ouvidos e tratados</strong>
        </p>
      </article>
    </>
  )
}

export default TopContent
