interface MainBannerProps {
  imageDesktop: string
  imageMobile: string
}

const MainBanner = ({ imageDesktop, imageMobile }: MainBannerProps) => {
  return (
    <>
      <img src={imageDesktop} alt="banner principal" className="desk" />
      <img src={imageMobile} alt="banner principal" className="mob" />
    </>
  )
}

export default MainBanner
