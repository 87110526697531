import TopContent from './TopContent'

const ContentSocial = () => {
  return (
    <section className="section-content">
      <TopContent />
      <article className="article">
        <h2 className="article_title">
          CONEXÃO E DIÁLOGO EM UM ECOSSISTEMA ABERTO
        </h2>
        <p className="article_text">
          Comprometemo-nos e cooperamos com nossos stakeholders local e
          globalmente, para melhor compreender e incorporar suas expectativas.
          Procuramos trabalhar juntos desenvolvendo soluções que nos ajudem em
          nossa transformação. Nossas publicações de relatórios nos permitem
          compartilhar nosso progresso e constituir ferramentas de
          desenvolvimento.
          <br />
          <strong>
            Lista de nossas associações e parcerias com as principais partes
            interessadas (organizações, esquemas e iniciativas, etc.).
          </strong>
          <br />
          <strong>
            Publicações anuais (Declaração de desempenho extra financeiro, Plano
            de Vigilância, Declaração da Escravidão Moderna, etc.).
          </strong>
        </p>
      </article>
    </section>
  )
}

export default ContentSocial
