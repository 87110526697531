import ContentSocial from './ContentGovernanca'
import LinkBack from '../AmbientalComponent/LinkBack'
import '../styles.scss'
import MainBanner from '../MainBanner'

const GovernancaComponent = () => {
  return (
    <main className="main-sustentabilidade-subpage">
      <div className="background-full">
        <section className="content--cta-banner">
          <LinkBack />
          <MainBanner
            imageDesktop="https://decathlonstore.vteximg.com.br/arquivos/banner-principal-governanca.png"
            imageMobile="https://decathlonstore.vteximg.com.br/arquivos/banner-principal-governanca-mobile.png"
          />
        </section>
        <section className="content--text">
          <div className="av-container">
            <div className="av-col-xs-24">
              <ContentSocial />
            </div>
            <div className="av-col-xs-24">
              <div className="av-col-xs-24">
                <a
                  href="https://www.decathlon.com.br/Institucional/sustentabilidade-brasil"
                  className="cta-voltar"
                >
                  Voltar
                </a>
              </div>
            </div>
          </div>
        </section>
      </div>
    </main>
  )
}

export default GovernancaComponent
